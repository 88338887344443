import { useEffect } from "react";
import { formatDate, getDays } from "../../../../../../utils/utilidades";
import { SkeletonLoader } from "../../../../../Common/Skeleton/Styled";
import { StoryStyle } from "./Styled";
import { useState } from "react";

//Creamos un componente para cada historia que toma el usuario para imprimir dos posibles colores si tiene informacion o imprime el loader mientras carga la info.
const Story = ({
  user,
  setShowModal,
  setSlideInitial,
  i,
  setActiveSlide,
  infoStories,
  destacada
}) => {
  //esta funcion establece la historia inicial con la que se desplegaran las historias, establece tambien la historia activa para validar el inicio de la reproduccion del video y por ultimo activa el modal.
  const handleDoubleclick = () => {
    if (setShowModal) {
      setSlideInitial(i);
      setActiveSlide(x => ({ ...x, current: i, next: i }));
      setShowModal(x => !x);
    }
  };
  const [randomImage, setRandomImage] = useState('');
  const images = [
    './img/icono1.jpg',
    './img/icono2.jpg',
    './img/icono3.jpg'

    // Agrega más URLs de imágenes aquí
  ];

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImage(images[randomIndex]);
  }, [])

  return (
    <StoryStyle destacada={destacada} onClick={handleDoubleclick}>
      {infoStories.loading === "succeded" &&
      infoStories.results.length > 0 ? 
      (<><img src={`https://colaboradordigital.blob.core.windows.net/ematch/archivos/`+user.img.slice(0, user.img.indexOf(';#'))} alt="logo persona" onError={(e) => { e.target.onerror = null; e.target.src = `${randomImage}`; }}/>{user.fecha_vencimiento === null ? '' :<span>{getDays(user.fecha_vencimiento === null ?  '' : user.fecha_vencimiento)} Días</span>}
      </>) : (
        <SkeletonLoader />
      )}
    </StoryStyle>
  );
};

export default Story;
