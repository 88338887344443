import React, { useEffect, useRef, useState } from "react";
import { PATH } from "../../../assets/variables";
import { getDays } from "../../../utils/utilidades";
import { ImgCap, Play, Video, WrapperVideo } from "./Styled";

const CapVideo = ({ archivo, custom, i, activeSlide, infoStories, fecha_vencimiento, tipo, post }) => {
  const [randomImage, setRandomImage] = useState('');
  const images = [
    './img/banner1.JPG',
    './img/banner2.JPG'
    // Agrega más URLs de imágenes aquí
  ];
  //La propiedad "archivo" es una combinacion de la url y el tipo de archivo divididos por una cadena ";#" para saber cuando devolver una etiqueta de imagen o de video
  const [play, setPlay] = useState(false);
  const [visible, setVisible] = useState(true);
  const video = useRef(null);
  const [url, type] = archivo.split(";#");
  //Manejador de reproductor de video a traves de el boton central del video.
  const handlerPlay = () => {
    if (play) {
      video.current.pause();
      setVisible(true);
    } else {
      setVisible(false);
      video.current.play();
    }
    setPlay(!play);
  };

  //Manejador de visibilidad de boton central del video.
  const handdlerVisibility = action => {
    if (action === "show") {
      setVisible(true);
    } else if (action === "hide") {
      if (!video.current?.paused) {
        setVisible(false);
      }
    } else if (action === "end") {
      setPlay(false);
      setVisible(true);
    }

    return function () {
      video.current.pause();
    };
  };

  let hideTimeOut = null;

  //Evento que crea un temporizador cuando el mouse se queda quieto sobre el video para ocultar el btn de central de play.
  const onStop = () => {
    handdlerVisibility("show");
    clearTimeout(hideTimeOut);

    hideTimeOut = setTimeout(() => {
      handdlerVisibility("hide");
    }, 3200);
  };


  // useEffect(() => {
  //   if (infoStories.loading === "succeded") {
  //     console.log(activeSlide)
  //     if (activeSlide.next === i) {
  //       console.log("entro video")
  //       video.current.play();
  //     } else if (activeSlide.next !== i) {
  //       video.current.pause();
  //     }
  //   }
  // }, [activeSlide, i, infoStories.loading]);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImage(images[randomIndex]);
  }, [])
  
  
  


  return (
    <WrapperVideo
      custom={custom}
      onMouseMove={onStop}
      onMouseLeave={() => handdlerVisibility("hide")}
      onMouseEnter={() => handdlerVisibility("show")}
    >
      {type?.startsWith("video") ? (
        <>
          <Play
            animate={{
              opacity: visible ? 1 : 0
            }}
            // initial={{ opacity: 0 }}
            onClick={handlerPlay}
          >
            <img
              src={play ? PATH + "img/Pause.svg" : PATH + "img/Play.svg"}
              alt="Play"
            />
          </Play>
          { custom ? (
          <Video
            // autoPlay
            controls 
            onPlay={() => setPlay(true)}
            onEnded={() => handdlerVisibility("end")}
            onPause={() => setPlay(false)}
            ref={video}
            src={post ? `https://colaboradordigital.blob.core.windows.net/ematch/archivos/${url}` : url}
          />
          ) : (
            <Video
            controls
              onPlay={() => setPlay(true)}
              onEnded={() => handdlerVisibility("end")}
              onPause={() => setPlay(false)}
              ref={video}
              src={post ? `https://colaboradordigital.blob.core.windows.net/ematch/archivos/${url}` : url}
            />
          )}
        </>
      ) : (
        <ImgCap custom={custom} src={post ? `https://colaboradordigital.blob.core.windows.net/ematch/archivos/${url}` : url} onError={(e) => { e.target.onerror = null; e.target.src = `${randomImage}`; }}/>
      )}
    </WrapperVideo>
  );
};

export default CapVideo;
